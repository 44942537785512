.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
  margin-top: 2rem;

}
.slide {
  height: 400px;
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}
 .slideShowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
} 
 .slideshowDots {
  text-align: center;
} 
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}
.slideshowDot.active {
  background-color: #6a0dad;
}