
.cont{
    background:#0077b6;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    

}
.cont .contact{
     padding-left: 4px;
     display: flex;
     margin-left: 20px;
     flex-direction: column;
     
}
.cont .email form{
    /* background: gold; */
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    height: 79%;
    
    

    

}
.cont .email form textarea{
    width: 80%;
    height: 50%;
}
.cont .email button{
    width: 80%;
    height: 20%;
    margin-top: 1.02em;
}
.cont .about{
    
    padding: 4px;
}
.cont h3{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.cont .email h3{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.cont .email{
    
}
faLocationDot{
    color: red;
}
.nono{
      margin-top: 6px; 
    

}
.back{
    background: #1a8ae5;
    padding: 5px;
    border-radius: 50%;
    margin-right: 5px;
    
    
    
}
.city{
    margin-left: 25px;
}
faPhone{
    color: red;
}
span{
    margin-top: 5px;
    margin-bottom: 5px;
}
hr {
    
    margin-bottom: 1rem;
    position : relative;   
    color : tomato ;
    width: 20%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
hr::after{
    content : "";  position : absolute;  width : 100%;  height : 3px;  color: white;  bottom: 0;  
   
}