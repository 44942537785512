@media only screen and (min-width:601px){
.navbar{
    
    background-color: whitesmoke;
    display: flex; 
    position: fixed;
    flex-direction: row;
    width: 100vw;
    height: 2.7em;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    
    
}
*{
    margin: 0;
    padding: 0;
}
.links{
    display: flex; 
    position: absolute; 
    right: 0.1em;  
    width: 60vw;
    justify-content: space-around;
    height: 2.5em;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    
    
    
}
a{
    display: flex;
    flex-direction: column;
    height: 2.5em;
    width: 100%; 
    align-items: center; 
    justify-content: center; 
    text-decoration: none;
    text-align: center;
    color: black;

 

}
.closebtn{
    display: none;
}
a:hover{
    background-color: beige;
    justify-content: center;

    
}
 .holder h5{
    display: flex;
    position: fixed;
    top:0;
    margin: 0;
    /* background: gold; */
    align-items: center;
    justify-content: center;
    z-index: 2
} 
.logo{
    width: 2.5rem;
    height: 2.7rem;
    margin: 0;
}
}

@media only screen and (max-width: 600px){
    
.navbar{
    /* background: wheat; */
    height: 100%;
    width: 0;
    position: fixed;
     z-index: 2; 
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    

}
a{
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
a:hover {
  color: #f1f1f1;
}
.navbar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
h1{
   background: gold;
    position: fixed;
    top:0;
    margin: 0; 
    /* background-color: whitesmoke; */
    display: flex;
    flex-direction: row;
    height: 1.5em;
    width: 100vw;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
}
.open{
     z-index: 1; 
    display: flex;
     /* flex-direction: column;  */
     position: fixed; 
    justify-content: center;
     right: 1.5em;
     top: 0.8em; 
    align-items: center;
}
    

}
