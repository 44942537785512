h2{
    
    display: flex;
    justify-content: center;
    
}
.services{
    display: flex;
    flex-direction: column;
}
img{
    width: 50vw;
}
.service{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
hr {
    
    
    position : relative;   
    color : tomato ;
    width: 20%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
 hr::after{
    content : "";  position : absolute;  width : 100%;  height : 3px;  color: white;  bottom: 0;  
   
} 
.wrap{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2); 
    align-items: center;
    margin-bottom: 4px;
    background: whitesmoke;
    
}